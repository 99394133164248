import React, { Component } from "react";

export default class BlogsImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        id="b4ce20e6-8fe7-43d5-87f7-68602c562594"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="1061"
        height="742.85506"
        viewBox="0 0 1061 742.85506"
      >
        <path
          d="M1118.5,516.01425c0,103.85867-61.74233,140.12223-137.90535,140.12223S842.68931,619.87292,842.68931,516.01425,980.59465,280.031,980.59465,280.031,1118.5,412.15559,1118.5,516.01425Z"
          transform="translate(-69.5 -78.57247)"
          fill={theme.compImgHighlight}
        />
        <polygon
          points="906.071 561.677 907.483 474.756 966.262 367.224 907.705 461.121 908.34 422.038 948.85 344.239 908.508 411.696 908.508 411.696 909.649 341.403 953.029 279.466 909.829 330.35 910.543 201.459 906.059 372.087 906.427 365.048 862.323 297.54 905.72 378.561 901.61 457.065 901.488 454.981 850.644 383.939 901.334 462.343 900.82 472.16 900.728 472.308 900.77 473.114 890.344 672.287 904.274 672.287 905.945 569.41 956.511 491.199 906.071 561.677"
          fill={theme.text}
        />
        <path
          d="M831.65688,421.48618c0,134.32387-79.85341,181.22475-178.35758,181.22475S474.94172,555.81005,474.94172,421.48618s178.35758-305.205,178.35758-305.205S831.65688,287.16231,831.65688,421.48618Z"
          transform="translate(-69.5 -78.57247)"
          fill={theme.compImgHighlight}
        />
        <polygon
          points="577.302 503.591 579.128 391.174 655.149 252.098 579.415 373.539 580.236 322.992 632.629 222.372 580.453 309.615 580.453 309.616 581.93 218.704 638.034 138.598 582.162 204.408 583.085 37.709 577.286 258.389 577.763 249.285 520.722 161.975 576.848 266.761 571.533 368.293 571.375 365.598 505.617 273.717 571.176 375.119 570.511 387.817 570.392 388.008 570.446 389.05 556.962 646.647 574.978 646.647 577.14 513.593 642.538 412.44 577.302 503.591"
          fill={theme.text}
        />
        <path
          d="M1107.47,769.93754c0,10.11-29.37988,19.55-80.13995,27.5-91.98,14.42-254.15,23.99-438.85,23.99-286.62,0-518.98-23.05-518.98-51.49,0-3.93,4.46-7.78,12.91-11.46,9.53-4.17,24.15-8.14,43.17005-11.85,39.21-7.65,97.13995-14.19,167.8-19.05,82.02-5.62994,181.21-8.98,288.21-9.11q3.435-.015,6.88995-.01,19.335,0,38.33.14,8.925.06006,17.77.16c63.02.67,122.66,2.46,176.86,5.16,2.42.12,4.83.25,7.23.37.82.04,1.64.09,2.46.13.22.01.44.02.66.04q15.975.84,31.25,1.79,5.01.3,9.93.63c31.87006,2.07,61.29,4.48,87.75,7.19q6.78.68994,13.30005,1.41c33.48,3.67,61.66992,7.84,83.38989,12.37C1089.51,754.53751,1107.47,762.0275,1107.47,769.93754Z"
          transform="translate(-69.5 -78.57247)"
          fill={theme.text}
        />
        <ellipse
          cx="752.96319"
          cy="688.14768"
          rx="113.08238"
          ry="26.20202"
          opacity="0.1"
        />
        <path
          d="M1107.47,769.93754c0,10.11-29.37988,19.55-80.13995,27.5-38.38-4.19-64.95-13.24-64.95-23.72,0-13.05,41.16-23.87,95.02991-25.87C1089.51,754.53751,1107.47,762.0275,1107.47,769.93754Z"
          transform="translate(-69.5 -78.57247)"
          opacity="0.1"
        />
        <path
          d="M632.15381,757.98614c0,17.26572-123.07592,31.25855-274.89132,31.25855-150.39039,0-272.5745-13.72617-274.85457-30.77131,29.19-12.74244,106.04-23.683,210.96763-30.9,20.50191-.5516,41.89565-.8458,63.88694-.8458C509.07789,726.7276,632.15381,740.72037,632.15381,757.98614Z"
          transform="translate(-69.5 -78.57247)"
          opacity="0.1"
        />
        <rect
          x="56.08151"
          width="456.00701"
          height="683.09115"
          fill={theme.text}
        />
        <rect
          x="83.66258"
          y="237.1972"
          width="428.42594"
          height="209.61613"
          fill={theme.dark}
        />
        <rect
          x="256.50394"
          y="241.79404"
          width="423.8291"
          height="199.50307"
          fill={theme.text}
        />
        <rect
          x="282.47612"
          y="271.21385"
          width="94.695"
          height="34.01665"
          fill={theme.imageHighlight}
        />
        <rect
          x="305.92003"
          y="330.97283"
          width="348.44084"
          height="9.19369"
          fill={theme.imageHighlight}
        />
        <rect
          x="305.92003"
          y="354.87642"
          width="348.44084"
          height="9.19369"
          fill={theme.imageHighlight}
        />
        <rect
          x="305.92003"
          y="378.78002"
          width="348.44084"
          height="9.19369"
          fill={theme.imageHighlight}
        />
        <rect
          x="305.92003"
          y="402.68361"
          width="348.44084"
          height="9.19369"
          fill={theme.imageHighlight}
        />
        <rect
          x="86.42068"
          y="77.22699"
          width="94.695"
          height="34.01665"
          fill={theme.compImgHighlight}
        />
        <rect
          x="109.86459"
          y="136.98598"
          width="348.44084"
          height="9.19369"
          fill={theme.compImgHighlight}
        />
        <rect
          x="109.86459"
          y="160.88957"
          width="348.44084"
          height="9.19369"
          fill={theme.compImgHighlight}
        />
        <rect
          x="109.86459"
          y="184.79316"
          width="348.44084"
          height="9.19369"
          fill={theme.compImgHighlight}
        />
        <rect
          x="109.86459"
          y="208.69676"
          width="348.44084"
          height="9.19369"
          fill={theme.compImgHighlight}
        />
        <rect
          x="90.09816"
          y="467.95881"
          width="94.695"
          height="34.01665"
          fill={theme.compImgHighlight}
        />
        <rect
          x="113.54207"
          y="527.71779"
          width="348.44084"
          height="9.19369"
          fill={theme.compImgHighlight}
        />
        <rect
          x="113.54207"
          y="551.62138"
          width="348.44084"
          height="9.19369"
          fill={theme.compImgHighlight}
        />
        <rect
          x="113.54207"
          y="575.52498"
          width="348.44084"
          height="9.19369"
          fill={theme.compImgHighlight}
        />
        <rect
          x="113.54207"
          y="599.42857"
          width="348.44084"
          height="9.19369"
          fill={theme.compImgHighlight}
        />
        <path
          d="M792.56281,219.0309s18.90356,24.22018,16.54061,41.35153S848.092,236.753,848.092,236.753s-14.17767-29.53681-6.4981-43.71448S792.56281,219.0309,792.56281,219.0309Z"
          transform="translate(-69.5 -78.57247)"
          fill="#ffb9b9"
        />
        <path
          d="M858.72526,709.34191s24.81092,27.17386,20.67577,33.08122-76.7957,28.35533-85.066,27.17386-12.40546-7.08883-9.45178-8.861,21.85724-11.81472,21.85724-11.81472l15.94988-18.31282s11.81472-6.4981,11.81472-10.04252S858.72526,709.34191,858.72526,709.34191Z"
          transform="translate(-69.5 -78.57247)"
          fill={theme.dark}
        />
        <path
          d="M816.783,739.46945s-9.45177,15.35914-11.224,15.94988,8.861,21.85723,8.861,21.85723L839.231,769.597l13.58693-11.224-2.95368-15.35914Z"
          transform="translate(-69.5 -78.57247)"
          fill="#ffb9b9"
        />
        <path
          d="M791.38134,446.46432l-11.81472,52.57552s-40.7608,91.5641-12.40546,107.514l62.02729,118.738,33.08123-14.7684-55.5292-121.69165,25.40165-140.00447Z"
          transform="translate(-69.5 -78.57247)"
          fill="#d0cde1"
        />
        <path
          d="M791.38134,446.46432l-11.81472,52.57552s-40.7608,91.5641-12.40546,107.514l62.02729,118.738,33.08123-14.7684-55.5292-121.69165,25.40165-140.00447Z"
          transform="translate(-69.5 -78.57247)"
          opacity="0.1"
        />
        <path
          d="M795.51649,454.73463h-5.99365a333.982,333.982,0,0,0,3.04,54.34772c4.13515,30.12754,14.76841,99.24367,14.76841,99.24367s-1.18148,12.9962,2.36294,17.13135,4.13515-1.18147,4.72589,8.27031,6.20273,75.167,6.20273,75.167,3.83978,13.44347-.29537,16.39715-12.40546,18.90356-6.4981,21.2665,46.66816,5.31663,48.44037,3.54442,0-112.8306,0-112.8306-1.18148-22.448-2.36295-23.62945-6.4981-17.13135-4.72589-20.67577.59074-14.17766.59074-15.94987-5.31663,0-.59074-7.67957,5.31663-69.11613,5.31663-69.11613,25.40165-38.98859,17.72208-49.0311Z"
          transform="translate(-69.5 -78.57247)"
          fill="#d0cde1"
        />
        <circle cx="739.60342" cy="110.33089" r="38.98859" fill="#ffb9b9" />
        <path
          d="M848.092,230.25489s-23.62945,5.90736-33.08122,20.085l-8.27031,1.77221v8.2703s-15.35914,49.0311-11.81472,70.2976,0,62.618-5.90737,73.842-7.67957,13.58693-4.72588,18.31282,7.08883,20.67577,4.13515,27.7646,0,14.17767,27.17386,15.94988,65.57171,1.77221,65.57171-7.67957-2.36294-36.03491,0-43.12374,3.54442-11.81472,2.36295-18.31282,2.36294-79.15864,2.36294-79.15864S886.48986,245.614,848.092,230.25489Z"
          transform="translate(-69.5 -78.57247)"
          fill={theme.dark}
        />
        <path
          d="M792.56281,412.79236l-32.49049,48.44036s-34.26269,31.309-21.85723,45.48669S783.111,465.36788,783.111,465.36788l35.44417-40.17006Z"
          transform="translate(-69.5 -78.57247)"
          fill="#ffb9b9"
        />
        <path
          d="M813.23858,758.373s-1.18148-7.67957-5.31663-7.67957S776.0222,769.597,776.0222,769.597s-36.62564,4.13516-22.448,11.81473,101.01588,8.861,101.60661,5.90736,1.77221-32.08116-2.36294-31.99046-37.21638,6.5888-37.21638,6.5888Z"
          transform="translate(-69.5 -78.57247)"
          fill={theme.dark}
        />
        <path
          d="M811.87686,168.76089a9.77913,9.77913,0,0,1-4.45607,1.00684c-1.59349.09057-3.49555-.008-4.343-1.36045a6.221,6.221,0,0,1-.54225-2.837,9.34634,9.34634,0,0,0-10.92238-7.96043,13.44458,13.44458,0,0,0,1.74051,5.54339,11.85549,11.85549,0,0,1-10.42222-1.38722l-.60006,7.95737c-2.1192,1.01275-4.834,1.224-6.68211-.2255s-1.99585-4.85139.09446-5.9225c-2.582-.5384-5.73992-1.69522-5.92989-4.32593-.17333-2.40039,2.37513-4.15727,2.84437-6.51772.38272-1.92518-.6727-4.01766.07418-5.83287.98118-2.38464,4.54365-2.99682,5.25837-5.4744.364-1.26178-.15973-2.61142-.03472-3.91869.32734-3.42283,4.63049-4.84393,8.06342-4.64939s7.17972,1.10618,10.09614-.71518c1.41407-.88311,2.40169-2.29662,3.64423-3.40818a10.82274,10.82274,0,0,1,12.568-1.09287c1.38063.85856,2.58527,2.04835,4.125,2.57019,3.151,1.06792,6.43846-.95269,9.73277-1.41848a8.0861,8.0861,0,0,1,6.77889,1.96365,5.54921,5.54921,0,0,1,1.01,6.686c1.93273-.32645,3.98079-.64221,5.8023.08175s3.21335,2.905,2.37066,4.67472a6.05576,6.05576,0,0,0-.735,1.50119c-.171,1.0296.8205,1.90986,1.82158,2.2051s2.0876.24,3.06773.59872c4.04919,1.48193,3.07759,7.95527,6.2646,10.85958a25.41622,25.41622,0,0,0,3.45482,2.11281,8.4062,8.4062,0,0,1,2.96417,10.15561c-.63881,1.41464-1.70919,2.76468-1.58024,4.3115.18086,2.1695,2.50546,3.35783,4.13683,4.79939a9.62473,9.62473,0,0,1,2.24041,11.05039c-1.49958,3.12814-4.74717,5.49944-5.07685,8.95275-.1433,1.50108.30776,2.99971.28208,4.5074a9.287,9.287,0,0,1-3.99193,7.01155,20.76464,20.76464,0,0,1-7.663,3.18852c-1.28776.30824-2.82439.79082-3.13606,2.07775-.36952,1.52585,1.33294,2.91435,1.15677,4.47439a2.59459,2.59459,0,0,1-1.0418,1.68634c-2.30448,1.80225-5.95147.25721-7.3606-2.30659s-1.229-5.67066-1.01054-8.588l.79458-10.60829c.24724-3.30077.22352-7.22049-2.46726-9.14818-2.97476-2.13114-7.30715-.40582-10.67124-1.84592-3.50523-1.50052-4.76265-5.892-4.62122-9.70232s1.23314-7.62654.60545-11.388c-.77818-4.66122-4.92422-7.794-9.11051-4.77343C814.14944,162.829,814.7847,167.05152,811.87686,168.76089Z"
          transform="translate(-69.5 -78.57247)"
          fill={theme.dark}
        />
        <path
          d="M844.54759,258.61022S820.91815,268.062,820.91815,288.147s1.18147,46.66816-2.95368,55.5292-7.67957,11.81472-5.31663,18.90356,6.4981,5.90736,0,11.224-5.90736-3.54441-6.4981,5.31663,5.90736,10.04251,0,14.17767-21.2665,20.67576-14.7684,25.99239,23.62945,22.448,29.53681,15.94987,8.861-23.03871,17.13135-21.85724,9.45177,1.77221,8.2703-4.13515-6.4981-5.90736.59074-7.67957,10.04251,2.363,7.67957-3.54441-4.13516-8.27031.59073-14.76841,7.08884-8.2703,6.4981-14.7684,12.9962-64.981,12.9962-64.981S878.81029,253.88433,844.54759,258.61022Z"
          transform="translate(-69.5 -78.57247)"
          opacity="0.1"
        />
        <path
          d="M838.0495,257.42875S814.42,266.88053,814.42,286.96556s1.18147,46.66815-2.95368,55.5292-7.67957,11.81472-5.31663,18.90355,6.4981,5.90737,0,11.224-5.90736-3.54442-6.49809,5.31663,5.90736,10.04251,0,14.17766-21.26651,20.67577-14.76841,25.99239,23.62945,22.448,29.53681,15.94988,8.861-23.03871,17.13135-21.85724,9.45178,1.77221,8.2703-4.13515-6.49809-5.90736.59074-7.67957,10.04251,2.36294,7.67957-3.54442-4.13515-8.2703.59074-14.7684,7.08883-8.27031,6.49809-14.7684,12.9962-64.981,12.9962-64.981S872.31219,252.70286,838.0495,257.42875Z"
          transform="translate(-69.5 -78.57247)"
          opacity="0.1"
        />
        <path
          d="M841.00318,255.65654s-23.62945,9.45178-23.62945,29.53681,1.18147,46.66816-2.95368,55.5292-7.67957,11.81472-5.31663,18.90356,6.4981,5.90736,0,11.224-5.90736-3.54441-6.49809,5.31663,5.90736,10.04251,0,14.17767-21.26651,20.67576-14.76841,25.99239,23.62945,22.448,29.53681,15.94987,8.861-23.03871,17.13135-21.85724,9.45178,1.77221,8.2703-4.13515-6.49809-5.90736.59074-7.67957,10.04251,2.363,7.67957-3.54442-4.13515-8.2703.59074-14.7684,7.08883-8.27031,6.49809-14.7684,12.9962-64.981,12.9962-64.981S875.26587,250.93065,841.00318,255.65654Z"
          transform="translate(-69.5 -78.57247)"
          fill={theme.dark}
        />
        <path
          d="M964.5,663.78694c0,51.68416,32.42393,93.50633,72.49367,93.50633"
          transform="translate(-69.5 -78.57247)"
          fill={theme.dark}
        />
        <path
          d="M1036.99367,757.29327c0-52.26489,36.18322-94.557,80.89874-94.557"
          transform="translate(-69.5 -78.57247)"
          fill={theme.imageHighlight}
        />
        <path
          d="M990.76582,668.47219c0,49.09446,20.67613,88.82108,46.22785,88.82108"
          transform="translate(-69.5 -78.57247)"
          fill={theme.imageHighlight}
        />
        <path
          d="M1036.99367,757.29327c0-66.78291,41.82217-120.82279,93.50633-120.82279"
          transform="translate(-69.5 -78.57247)"
          fill={theme.dark}
        />
        <path
          d="M1021.74287,757.9525s10.2808-.31664,13.37917-2.523,15.81445-4.84077,16.58308-1.30233,15.45013,17.59858,3.84316,17.6922-26.96943-1.808-30.06184-3.69167S1021.74287,757.9525,1021.74287,757.9525Z"
          transform="translate(-69.5 -78.57247)"
          fill="#a8a8a8"
        />
        <path
          d="M1055.75542,770.58768c-11.607.09365-26.96942-1.808-30.06184-3.69167-2.355-1.43454-3.29349-6.582-3.60749-8.9569-.21746.00935-.34322.01338-.34322.01338s.65117,8.29153,3.74358,10.17525,18.45487,3.78532,30.06184,3.69167c3.35047-.027,4.50778-1.21907,4.44423-2.9846C1059.52707,769.9015,1058.24922,770.56757,1055.75542,770.58768Z"
          transform="translate(-69.5 -78.57247)"
          opacity="0.2"
        />
      </svg>
    );
  }
}
